import React, { useContext, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/utilities/Seo"

import RisingSpans from "../components/components/RisingSpans"

import Construction from '../images/svgs/services/panels/construction.svg'
import Engineering from '../images/svgs/services/panels/engineering.svg'
import Procurement from '../images/svgs/services/panels/procurement.svg'
import ProjectManagement from '../images/svgs/services/panels/project-management.svg'

import Monitoring from '../images/svgs/services/panels/monitoring.svg'
import Preventive from '../images/svgs/services/panels/preventive.svg'
import Corrective from '../images/svgs/services/panels/corrective.svg'
import Systems from '../images/svgs/services/panels/systems.svg'


import Panels from '../components/components/Panels'
import {usePresence, motion, useAnimation} from 'framer-motion'

import { SectionNavContext } from '../components/utilities/SiteContexts'
import ContactSection from "../components/components/ContactSection"

import {servicesHolder} from './pages.module.scss'

const epcPanels = [
  {
    title: 'Engineering',
    svg: <Engineering />,
    bullets: [
      'Performance oriented simulation via specialized software',
      'State of the art technology for site studies and planning – e.g. drones',
      'Open communication with equipment suppliers',
      'Project redesign and optimisation'
    ]
  },
  {
    title: 'Purchasing & Procurement',
    svg: <Procurement />,
    bullets: [
      'Well established supply chain',
      'Large warehouse and stocks of the main equipment',
      'Development of group  supply coordination',
      'Cross-selling business model'
    ]
  },
  {
    title: 'Project Management',
    svg: <ProjectManagement />,
    bullets: [
      'Project management at highest  level, multiple projects at the same time',
      'Creation of project coordination center – applications and tools',
      'Contract and cash-flow management'
    ]
  },
  {
    title: 'Construction',
    svg: <Construction />,
    bullets: [
      'Securing of all type of civil, mechanical and electrical works',
      'Planning, reporting and  controlling – advanced tools and applications',
      'Detailed Quality Control procedures'
    ]
  }
]



const managementPanels = [
  {
    title: 'International Monitoring Center',
    svg: <Monitoring />,
    bullets: [
      'Ongoing monitoring of system performance',
      'Data collection',
      'Power profile analysis',
      'Energy accounting and yield forecasting and reporting'
    ]
  },
  {
    title: 'Preventive Maintenance',
    svg: <Preventive />,
    bullets: [
      'Preventive maintenance planning',
      'Technical preventive activities',
      'Opportunities for improvement',
      'Events logs and maintenance logs'
    ]
  },
  {
    title: 'Corrective Maintenance & Troubleshooting',
    svg: <Corrective />,
    bullets: [
      'Identification and localization of accidents',
      'Emergency repairs',
      'Major repairs',
      'Optimization system repairs'
    ]
  },
  {
    title: 'Other System Services',
    svg: <Systems />,
    bullets: [
      'Thermal vision diagnostics: thermal imaging of PV modules, inverters and LV/MV switchboards',
      'PV module cleaning',
      'Maintenance of the terrain',
      'Spare parts management',
      'Warranty management',
      'Power generation forecasting',
      'Performance analysis and improvement: portfolio level (group of plants); plant level; inverter level; string level'
    ]
  }
]

const articleVariants = {
  pre: {
    opacity:0,
    y:'2rem'
  },
  vis: {
    opacity:1,
    y:'0rem',
    transition: {
      type: 'tween',
      duration:0.8
    }
  },
  post: {
    opacity:0,
    y:'-2rem',
    transition: {
      type: 'tween'
    }
  }
}



const OurServicesPage = () => {
  // const isBrowser = typeof window !== "undefined"
  const [, setSectionNav] = useContext(SectionNavContext)
  const pageControls = useAnimation()
  const headerControls = useAnimation()
  const subTitleControls = useAnimation()

  const [isPresent, safeToRemove] = usePresence()

  const introAnimation = async () => {
   pageControls.start('vis')
   await headerControls.start('vis')
   subTitleControls.start('vis')
   }


  useEffect(()=> {
    introAnimation()
    setSectionNav([])
  }, [])

  useEffect(() => {

    const exitAnimation = async () => {
      await pageControls.start('post')
      safeToRemove()
  
    }

    if(!isPresent) {
      exitAnimation()
    }
  }, [isPresent, safeToRemove, pageControls])

  return (
    <motion.article className={servicesHolder} variants={articleVariants} animate={pageControls} initial="pre" >
      <Seo title="Our Services" description="What can we do for you?" />

      <section className="intro">
        <RisingSpans staggerChildren={0.3} controls={headerControls} exitAnimation={false}><h1>Our Services</h1></RisingSpans>
        <motion.h6 variants={articleVariants} animate={subTitleControls} initial="pre">Carbon Climate Solutions provide a wide range of services that can take your solar project from project development, through every aspect of the build, to post-build management and energy storage.</motion.h6>
        <StaticImage
            src="../images/services/topPanels.jpg"
            alt="Benefits of CCS"
            objectPosition="50% 100%"
            width={1200}
            objectFit="cover"
            placeholder="blurred"
          />
      </section>
      <section className="services">
        <ol>
          <li>
              <h6>1.</h6>
              <div className="inner">
                <div className="title">
                  <h2>Photovoltaic project development</h2>
                </div>
                <div className="info">
                  <p>Our team can take care of:</p>
                  <ul>
                    <li>Site feasibilty studies</li>
                    <li>HV & MV grid connection</li>
                    <li>Permitting</li>
                  </ul>
                </div>
              </div>
          </li>
          <li>
              <h6>2.</h6>
              <div className="inner">
                <div className="title">
                  <h2>Engineering, procurement & construction (EPC)</h2>
                </div>
                <div className="info">
                  <p>Using our extensive experience CCS and it’s partners will create and design a full turnkey project and optimise materials and technology to deliver on time and within budget.</p>
                </div>
                <Panels panels={epcPanels} />
              </div>
          </li>
          <li>
              <h6>3.</h6>
              <div className="inner">
                <div className="title">
                  <h2>Photovoltaic asset management</h2>
                </div>
                <div className="info">
                  <p>We offer full or partial operations & maintenance (O&M) packages customized to serve each customer’s needs.</p>
                  <p>We will identify and provide the most suitable and cost efficient monitoring solution and O&M package for your power plant given its specific characteristics</p>
                </div>

                <Panels panels={managementPanels} />
              </div>
          </li>
          <li>
              <h6>4.</h6>
              <div className="inner">
                <div className="title">
                  <h2>Utility scale energy storage systems</h2>
                </div>
                <div className="info">
                  <ul>
                    <li>Solutions based on components from large-scale systems</li>
                    <li>Frequency regulation to ensure stable grid operation</li>
                    <li>Emergency backup</li>
                  </ul>
                </div>
              </div>
          </li>
        </ol>
      </section>
      <ContactSection isDark={true} title={"Need more information?"} subTitle="Please get in touch."/>
    </motion.article>
  )
}

export default OurServicesPage
