import React, { useState, useEffect } from 'react'
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'
import {panelsHolder} from './Panels.module.scss'
import Cross from '../../images/svgs/services/panels/Cross.svg'

function Panel({panel, expanded, setExpanded, zIndex, setZIndex}) {
  const [show, setShow] = useState(false)
  const [thisZ, setThisZ] = useState(1)

  const expand = () => {
    setShow(true)
    setExpanded(panel.title)
    setZIndex(ZIndex => ZIndex + 1)
    setThisZ(zIndex + 1)
  }

  const contract = () => {
    setShow(false)
    setExpanded(false)
  }

  useEffect(()=>{
    if(expanded !== panel.title) {
      setShow(false)
    }
  }, [expanded, setShow, panel])



  return (
    <AnimateSharedLayout type="crossfade">
      <AnimatePresence>
      {
        !show ? <motion.li initial={false} layout layoutId="li" className="compact" style={{'zIndex':thisZ}} key="compact">
          <motion.button onClick={expand}>
          <motion.div layoutId="svg" className="svgHolder">{panel.svg}</motion.div>
          <motion.div className="panelInfo" layoutId="panelInfo">
            <motion.h3 layoutId="title">{panel.title}</motion.h3>
          </motion.div>
        </motion.button>
        </motion.li> :
        <motion.li initial={false} layoutId="li" className="popOut" style={{'zIndex':thisZ}} key="popOut" layout>
          <motion.div layoutId="svg" className="svgHolder" >{panel.svg}</motion.div>
          <motion.div className="panelInfo">
            <motion.h3 layoutId="title">{panel.title}</motion.h3>
            <motion.ul className="details" animate={{opacity:1}} initial={{opacity:0}} exit={{opacity:0}}>
              {
                panel.bullets.map((bullet, index)=><li key={index}>{bullet}</li>)
              }
            </motion.ul>
            <motion.button className="cross" onClick={contract} ><Cross /></motion.button>
          </motion.div>
        </motion.li>
      }
      </AnimatePresence>
      {
        show && <li className="spacer" />
      }
    </AnimateSharedLayout>
  )
}


export default function Panels({panels}) {
const [expanded, setExpanded] = useState(false)
const [zIndex, setZIndex] = useState(1)

return (
  <>
  <ul className={panelsHolder}>
    {
      panels.map((panel) => <Panel panel={panel} key={panel.title} expanded={expanded} setExpanded={setExpanded} zIndex={zIndex} setZIndex={setZIndex} />)
    }
  </ul>
  <AnimatePresence> { expanded &&
    <motion.div
    className="shrowd"
    animate={{opacity:1}}
    initial={{opacity:0}}
    exit={{opacity:0}}
    style={{'zIndex':zIndex-1}}
    onClick={()=>setExpanded(null)}></motion.div> } </AnimatePresence>
  </>
)
}

